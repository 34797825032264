import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/modules/Layout"
import BodyBlocks from "../../components/technical/BodyBlocks"
import Seo from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Seo"

const IndexPageDe = ({ data }) => {
  const { story } = data
  const language = story.fields.language

  const content = JSON.parse(story.content)

  let seoImage = null

  if (
    content.body &&
    content.body[0] &&
    content.body[0].image &&
    content.body[0].image.filename
  ) {
    seoImage = content.body[0].image.filename
  }

  return (
    <Layout language={language}>
      <Seo
        title={content.headline}
        content={content}
        og_image={seoImage}
        language={language}
        name={story.name}
      />
      <BodyBlocks body={content.body} />
    </Layout>
  )
}

export const data = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "de/" }) {
      name
      content
      full_slug
      fields {
        language
      }
    }
  }
`

export default IndexPageDe
